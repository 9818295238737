import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import Block from '../../components/block/Block';
import Hero from '../../components/hero/Hero';
import Footer from '../../components/footer/Footer';
import Loader from '../../components/loader/Loader';
import Subscribe from '../../components/subscribe/Subscribe';

import IMG_MOCKUP_ACTIVITIES from '../../../img/landing/mockup-activities.png';
import IMG_MOCKUP_TRIBE from '../../../img/landing/mockup-tribe.png';
import IMG_HERO from '../../../img/landing/hero.jpg';

import styles from './Landing.module.css';


export default class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

    this.IMAGES = [
      IMG_HERO, IMG_MOCKUP_ACTIVITIES, IMG_MOCKUP_TRIBE,
    ];
  }

  componentDidMount() {
    let imagesLoaded = 0;
    this.IMAGES.forEach((image) => {
      const img = new Image();
      img.onload = () => {
        imagesLoaded++;

        if (imagesLoaded === this.IMAGES.length) {
          this.onImagesLoaded();
        }
      };
      img.src = image;
    });
  }

  /* Handlers */
  onImagesLoaded() {
    this.setState({ loading: false });
  }

  /* Render */
  render() {
    const { loading } = this.state;

    const landingClassNames = cx(styles.landing, {
      [styles.pageLoaded]: !loading,
    });
    const containerClassNames = cx(styles.container, 'container');
    return (
      <div className={landingClassNames}>
        {/* Hero */}
        <div className={styles.hero}>
          <Hero loaded={!loading} />
        </div>

        <div className={containerClassNames}>

          {/* Intro */}
          <div className={styles.intro}>
            <div className={styles.introText}>
              <FormattedMessage id="landing.intro" />
            </div>
            <div className={styles.activitiesMockup}>
              <img src={IMG_MOCKUP_ACTIVITIES} alt="Activities" />
            </div>
          </div>

          {/* Activities */}
          <div className={styles.activities}>
            <div className={styles.activitiesBlock}>
              <Block
                icon="calendar"
                text="landing.activities.text"
                title="landing.activities.title"
              />
            </div>
          </div>

          {/* Tribe */}
          <div className={styles.tribe}>
            <div className={styles.tribeMockupWrapper}>
              <div className={styles.tribeMockup}>
                <img src={IMG_MOCKUP_TRIBE} alt="Tribe" />
              </div>
            </div>
            <div className={styles.tribeBlock}>
              <Block
                icon="tribe"
                text="landing.tribe.text"
                title="landing.tribe.title"
              />
            </div>
          </div>

          {/* Subscribe */}
          <div className={styles.subscribe}>
            <Subscribe />
          </div>
        </div>


        {/* Footer */}
        <Footer />

        {/* Loader */}
        <Loader visible={loading} />
      </div>
    );
  }
}
