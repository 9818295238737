import enJson from '../lang/en';

function digDeeper(result, object, prefix) {
  for (const key in object) { // eslint-disable-line
    if (typeof object[key] === 'string' || typeof object[key] === 'number') {
      result[`${prefix}.${key}`] = object[key]; // eslint-disable-line
    } else {
      digDeeper(result, object[key], `${prefix}.${key}`);
    }
  }
}

export default function messages(lang) {
  const imported = enJson;
  const result = {};

  for (const key in imported) { // eslint-disable-line
    if (imported[key] && key !== 'default') {
      if (typeof imported[key] === 'string' || typeof imported[key] === 'number') {
        result[key] = imported[key];
      } else {
        digDeeper(result, imported[key], key);
      }
    }
  }

  return result;
}
