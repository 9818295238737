import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import cx from 'classnames';

import Icon from '../icon/Icon';

import styles from './Block.module.css';

export default class Block extends Component {
  render() {
    const {
      center, icon, size, title, text,
    } = this.props;

    const blockClassnames = cx(styles.block, {
      [styles.blockCentered]: center,
      [styles[`block${size}`]]: size,
    });

    return (
      <div className={blockClassnames}>
        <div className={styles.head}>
          <Icon
            className={styles.icon}
            name={icon}
          />
          <div className={styles.title}>
            <FormattedMessage id={title} />
          </div>
        </div>
        <div className={styles.text}>
          <FormattedHTMLMessage id={text} />
        </div>
      </div>
    );
  }
}

Block.defaultProps = {
  size: 'md',
};

Block.propTypes = {
  center: PropTypes.bool,
  icon: PropTypes.string,
  size: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
};
