import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import Icon from '../icon/Icon';

import styles from './Loader.module.css';

export default class Loader extends Component {
  /* Render */
  render() {
    const { visible } = this.props;
    return (
      <CSSTransition
        in={visible}
        classNames={{
          exit: styles.onExit,
          exitActive: styles.onExitActive,
          exitDone: styles.onExitActive,
        }}
        timeout={1000}
        unmountOnExit
      >
        <div className={styles.loader}>
          <Icon
            className={styles.icon}
            name="wave"
          />
        </div>
      </CSSTransition>
    );
  }
}

Loader.propTypes = {
  visible: PropTypes.bool,
};
