import React, { Component } from 'react';
import cx from 'classnames';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';

import Block from '../block/Block';
import Button from '../button/Button';
import Input from '../input/Input';

import styles from './Subscribe.module.css';

export default class Subscribe extends Component {
  /* Constructor */
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      firstName: '',
      lastName: '',

      loading: false,
      success: false,
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  /* Handlers */
  onInputChange(prop, value) {
    const state = {
      [prop]: value,
    };

    this.setState(state);
  }

  onSubmit(event) {
    event.preventDefault();

    const {
      email,
      firstName,
      lastName,
    } = this.state;
    const valid = email && firstName && lastName;

    this.setState({ loading: true });
    if (valid) {
      setTimeout(() => {
        this.setState({
          loading: false,
          success: true,
        });
        console.log('[Subscribe] submit() - DONE');
      }, 1000);
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  /* Render */
  render() {
    const {
      email,
      firstName,
      lastName,
      loading,
    } = this.state;

    const classNames = cx(styles.subscribe, 'subscribe');

    return (
      <div className={classNames}>
        <Block
          center
          icon="activities"
          title="landing.subscribe.title"
          text="landing.subscribe.text"
        />
        <div className={styles.form}>
          <form onSubmit={this.onSubmit}>
            <div className={styles.formRow}>
              <Input
                disabled={loading}
                label="landing.subscribe.firstName.label"
                onChange={this.onInputChange}
                placeholder="landing.subscribe.firstName.placeholder"
                prop="firstName"
                required
                value={firstName}
              />
              <Input
                disabled={loading}
                label="landing.subscribe.lastName.label"
                onChange={this.onInputChange}
                placeholder="landing.subscribe.lastName.placeholder"
                prop="lastName"
                required
                value={lastName}
              />
            </div>
            <div className={styles.formRow}>
              <Input
                disabled={loading}
                label="landing.subscribe.email.label"
                onChange={this.onInputChange}
                placeholder="landing.subscribe.email.placeholder"
                prop="email"
                required
                type="email"
                value={email}
              />
            </div>
            <div className={styles.formRow}>
              <Button
                center
                disabled={loading}
                id="landing.subscribe.submit"
                type="submit"
              />
            </div>
          </form>
          {this.renderFormOverlay()}
        </div>
      </div>
    );
  }

  renderFormOverlay() {
    const { success } = this.state;

    return (
      <CSSTransition
        classNames="fade"
        in={success}
        timeout={300}
        unmountOnExit
      >
        <div className={styles.formSuccess}>
          <div className={styles.formSuccessTitle}>
            <FormattedMessage id="landing.subscribe.success.title" />
          </div>
          <div className={styles.formSuccessText}>
            <FormattedHTMLMessage id="landing.subscribe.success.text" />
          </div>
        </div>
      </CSSTransition>
    );
  }
}
