import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default class Icon extends Component {
  render() {
    const { className, name } = this.props;
    const iconClassNames = cx('icon', `icon-${name}`, className);
    return (
      <div className={iconClassNames} />
    );
  }
}

Icon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
};
