import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

export default class TermsAndConditions extends Component {
  /* Render */
  render() {
    return (
      <div className="">
        <FormattedMessage id="legal.tac.title" />
      </div>
    );
  }
}
