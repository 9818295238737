import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import styles from './Input.module.css';

class Input extends Component {
  /* Lifecycle */
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const { onChange, prop } = this.props;

    onChange(prop, event.target.value);
  }

  /* Render */
  render() {
    const {
      label, disabled, placeholder, required, intl, type, value,
    } = this.props;

    return (
      <div className={styles.input}>
        <label htmlFor={label} style={styles.label}>
          <span className={styles.labelNative}>
            <FormattedMessage id={label} />
          </span>
          <input
            className={styles.inputNative}
            disabled={disabled}
            id={label}
            onChange={event => this.onChange(event)}
            placeholder={intl.messages[placeholder]}
            required={required}
            type={type}
            value={value}
          />
        </label>
      </div>
    );
  }
}

Input.defaultProps = {
  type: 'text',
};

Input.propTypes = {
  disabled: PropTypes.bool,
  intl: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  prop: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
};

export default injectIntl(Input);
