import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from '../icon/Icon';

import styles from './Footer.module.css';

const URL_FACEBOOK = 'https://facebook.com/joinquiver';
const URL_INSTAGRAM = 'https://instagram.com/joinquiver';

export default class Footer extends Component {
  render() {
    return (
      <div className={styles.footer}>
        <div className={styles.icon}>
          <Icon name="wave" />
        </div>
        <div className={styles.content}>
          <FormattedMessage id="social.followUs" />
          <a
            className={styles.socialLink}
            href={URL_FACEBOOK}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Icon name="facebook" />
          </a>
          <a
            className={styles.socialLink}
            href={URL_INSTAGRAM}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Icon name="instagram" />
          </a>
        </div>
      </div>
    );
  }
}
