import React from 'react';
import ReactDOM from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';

/* Translations */
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import messages from './js/util/intl-messages';

/* Styles */
import './style/variables.css';
import './style/reset.css';
import './style/icomoon.css';
import './style/index.css';

import App from './js/App';

// import * as serviceWorker from './serviceWorker';


// kick off the polyfill!
smoothscroll.polyfill();

const LOCALE = 'en';
addLocaleData(en);

ReactDOM.render((
  <IntlProvider
    defaultLocale={LOCALE}
    locale={LOCALE}
    messages={messages(LOCALE)}
  >
    <App />
  </IntlProvider>
),
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
