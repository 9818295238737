import React, { Component } from 'react';

import IMG_LOGO from '../../../img/logo/logo.svg';

import styles from './Logo.module.css';

export default class Logo extends Component {
  render() {
    return (
      <div className={styles.container}>
        <img className={styles.logo} src={IMG_LOGO} alt="Quiver" />
      </div>
    );
  }
}
