import React, { Component } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../button/Button';
import Icon from '../icon/Icon';
import Logo from '../logo/Logo';

import styles from './Hero.module.css';

const COPYRIGHT = {
  name: 'Fredrik Öhlander',
  url: 'https://unsplash.com/@fredrikohlander',
};

export default class Hero extends Component {
  /* Lifecycle */
  constructor(props) {
    super(props);

    this.onButtonClick = this.onButtonClick.bind(this);
  }

  /* Handlers */
  onButtonClick() {
    document.querySelector('.subscribe').scrollIntoView({
      behavior: 'smooth',
    });
  }


  /* Render */
  render() {
    const { loaded } = this.props;
    const heroClassNames = cx(styles.hero, {
      [styles.isLoaded]: loaded,
    });
    const containerClassNames = cx(styles.container, 'container');

    return (
      <div className={heroClassNames}>
        <div className={styles.huge}>
          <div className={styles.image}>
            <div className={styles.backdrop} />
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={containerClassNames}>
            <div className={styles.logo}>
              <Logo />
            </div>
            <div className={styles.content}>
              <div className={styles.subtitle}>
                <FormattedHTMLMessage id="landing.baseline" />
              </div>
              <div className="">
                <Icon name="arrow-right" />
              </div>
              <div className={styles.button}>
                <Button
                  color="white"
                  icon="arrow-right"
                  id="landing.actions.ctaHero"
                  onClick={this.onButtonClick}
                />
              </div>
            </div>
            <div className={styles.copyright}>
              <a
                className={styles.copyrightUrl}
                href={COPYRIGHT.url}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Icon name="camera" className={styles.copyrightUrlIcon} />
                <span className={styles.copyrightUrlText}>
                  {COPYRIGHT.name}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Hero.propTypes = {
  loaded: PropTypes.bool,
};
