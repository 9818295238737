import general from './general';
import landing from './landing';
import legal from './legal';
import social from './social';

export default {
  general,
  landing,
  legal,
  social,
};
