import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import Icon from '../icon/Icon';

import styles from './Button.module.css';

export default class Button extends Component {
  /* Lifecycle */
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  /* Handlers */
  onClick() {
    const { onClick } = this.props;

    if (onClick) onClick();
  }


  /* Render */
  render() {
    const {
      color, center, disabled, id, type,
    } = this.props;

    const buttonClassNames = cx(styles.button, {
      [styles[`color-${color}`]]: true,
      [styles.buttonCentered]: center,
    });

    return (
      <button
        className={buttonClassNames}
        disabled={disabled}
        type={type}
        onClick={this.onClick}
      >
        <FormattedMessage id={id} />
        {this.renderIcon()}
      </button>
    );
  }

  renderIcon() {
    const { icon } = this.props;

    if (icon) {
      return (
        <Icon className={styles.icon} name={icon} />
      );
    }

    return null;
  }
}

Button.defaultProps = {
  color: 'blue',
  type: 'button',
};

Button.propTypes = {
  center: PropTypes.bool,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
};
