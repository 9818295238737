export default {
  actions: {
    ctaHero: 'Get on board',
  },

  activities: {
    title: 'Join nearby activities',
    text: '<span>Quiver offers a handpicked collection of local shapers, surf camps, festivals, and other surf-related activities that share the positive surf-mindset.</span><span>Whether you’re looking for some cool workshops, a place to fix your board, rent one or even get one made for you, Quiver will guide you to the finest experiences in town!</span>',
  },

  baseline: 'Let\'s catch<br/>more waves!',
  intro: 'Quiver brings surfers in and around Barcelona together to co-create awesome surf-related experiences.',

  subscribe: {
    title: 'Get on board',
    text: 'Quiver will be available in all app stores soon.<br/>Can\'t wait? Join the Quiver waiting list and get early access.',

    firstName: {
      label: 'First name',
      placeholder: 'Your first name',
    },

    lastName: {
      label: 'Last name',
      placeholder: 'Your last name',
    },

    email: {
      label: 'Email address',
      placeholder: 'Your email address',
    },

    submit: 'Get on board',
    success: {
      title: 'Awesome! ✌🏼',
      text: 'Thanks for subscribing.<br />We\'ll be in touch soon.',
    },
  },

  tribe: {
    title: 'Collaborate with others',
    text: '<span>Do you need a ride, want to get more knowledge about a certain spot or just looking for a surfmate? Find it all on Quiver!</span><span>Quiver wants to bring like-minded people together within a tribe. This tab is a gathering place where surfers can easily connect based on what they need and what they can offer to one another.</span>',
  },
};
