import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

/* Views */
import Landing from './views/landing/Landing';
import TermsAndConditions from './views/legal/TermsAndConditions';

export default class App extends Component {
  render() {
    return (
      <div>
        <Router basename="/quiver">
          <Switch>
            <Route
              exact
              path="/"
              component={Landing}
            />
            <Route
              path="/terms-and-conditions"
              component={TermsAndConditions}
            />
          </Switch>
        </Router>
      </div>
    );
  }
}
